<template>
    <!DOCTYPE html>
    <html>
        <head>
            <meta charset="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <title>Entro-file</title>
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bulma@0.9.2/css/bulma.min.css" />
        </head>

        <body>
            <section class="section">
              <div class="columns is-centered">
                <img src="./assets/entrofilelogo.png" alt="Entrofile logo" style="width: 25%; height: 25%" >
              </div>
                <Dashboard />
            </section>
        </body>
    </html>
</template>

<script>
import Dashboard from './components/Dashboard';

export default {
    name: 'App',
    components: {
        Dashboard,
    },
};
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}
</style>
