<template>
    <div class="columns is-centered" v-if="!isLoading">
        <div class="column is-one-quarter">
            <h2 class="subtitle">Enter password to download file:</h2>
            <input v-model="password" class="input is-primary" type="text" placeholder="Download Password" />
            <br />
            <div class="columns is-grouped-right py-4">
                <div class="column">
                    <button class="button is-primary is-fullwidth" @click="downloadFile">Request Download</button>
                </div>
                <div class="column">
                    <button class="button is-light is-fullwidth" @click="cancel">Cancel</button>
                </div>
            </div>
            <div class="notification is-warning is-light columns" v-if="errorMessage">
                <button class="delete" @click="this.errorMessage = null"></button>
                <p>{{ errorMessage }}</p>
            </div>
            <div class="notification is-success columns is-light" v-if="downloadSuccess">
                <button class="delete" @click="this.downloadSuccess = null"></button>
                <h3>File successfully download</h3>
            </div>
        </div>
    </div>
    <div class="columns is-centered" v-if="isLoading">
        <atom-spinner :animation-duration="1000" :size="100" :color="'#ff1d5e'" />
    </div>
</template>

<script>
import FileDownload from 'js-file-download';
import { AtomSpinner } from 'epic-spinners';

export default {
    name: 'dashboard',
    components: {
        AtomSpinner,
    },
    data() {
        return {
            password: '',
            isLoading: false,
            errorMessage: null,
            downloadSuccess: null,
        };
    },
    methods: {
        cancel() {
            this.password = '';
            this.downloadSuccess = null;
            this.errorMessage = null;
        },
        downloadFile() {
            this.isLoading = true;
            this.errorMessage = null;

            // extract query parameters from uri
            let uri = window.location.href.split('?');
            let params = {};

            if (uri.length === 2) {
                let vars = uri[1].split('&');
                let tmp = '';
                vars.forEach(function (v) {
                    tmp = v.split('=');
                    if (tmp.length === 2) params[tmp[0]] = tmp[1];
                });
            }

            this.axios
                .post(
                    `${process.env.VUE_APP_URL_SUFFIX}/api/download?auth_token=${params['auth_token']}`,
                    { password: this.password },
                    { responseType: 'blob' },
                )
                .then(
                    (response) => {
                        this.isLoading = false;
                        FileDownload(
                            response.data,
                            response.headers['content-disposition'].split('=')[1],
                            response.headers['content-type'],
                        );
                        this.downloadSuccess = true;
                    },
                    (error) => {
                        this.isLoading = false;
                        if (Number(error.response.status) !== 402) {
                            const res = error.response || {};
                            const message = res.message || JSON.stringify(res);
                            this.errorMessage = message + '. Failed to download file.';
                        } else {
                            this.errorMessage = 'Download failed due to incorrect password.';
                        }
                    },
                );
        },
    },
};
</script>

<style></style>
